<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-card class="pa-6 mt-n8" width="100%" flat>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="7" sm="7">
            <v-btn icon class="mt-n3" @click="cancel()">
              <v-icon> mdi-less-than </v-icon>
            </v-btn>
            <span class="text-h4">
              Untitled - {{ convertDate(this.form.updatedAt) }}
            </span>
          </v-col>
          <v-col cols="12" md="2" sm="2" class="text-right mt-n1">
            <v-select
              v-model="status"
              outlined
              :items="statusItems"
              item-value="name"
              background-color="white"
              style="border-radius: 0px"
              disabled
            >
              <template v-slot:selection="{ item }">
                <span v-if="item.name == 'เปิดใช้งาน'" style="color: #11c872">
                  <v-icon style="color: #11c872"> mdi-circle </v-icon>
                  <v-icon style="color: #11c872"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                  <v-icon style="color: red"> mdi-circle </v-icon>
                  <v-icon style="color: red"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                  <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                  <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <span v-if="item.name == 'เปิดใช้งาน'" style="color: #11c872">
                  <v-icon style="color: #11c872"> mdi-circle </v-icon>
                  <v-icon style="color: #11c872"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ปิดใช้งาน'" style="color: red">
                  <v-icon style="color: red"> mdi-circle </v-icon>
                  <v-icon style="color: red"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
                <span v-if="item.name == 'ฉบับร่าง'" style="color: #026aa2">
                  <v-icon style="color: #026aa2"> mdi-circle </v-icon>
                  <v-icon style="color: #026aa2"> mdi-blank </v-icon>
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-col>
          <v-divider class="mt-2" vertical style="height: 56px"></v-divider>
          <v-col cols="12" md="1" sm="1" class="text-right">
            <v-btn
              @click="cancel()"
              text
              color="#2ab3a3"
              style="
                border: solid 1px #2ab3a3;
                border-radius: 0px;
                padding: 12px 16px;
                gap: 8px;
                width: 48px;
                height: 48px;
              "
            >
              <v-icon large> mdi-eye-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <v-btn
              @click="submit()"
              color="#2AB3A3"
              disabled
              elevation="0"
              style="
                border-radius: 0px;
                padding: 12px 16px;
                gap: 8px;
                height: 48px;
              "
            >
              ยืนยันการเพิ่มทุนการศึกษา
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-col class="mt-10">
      <span
        style="
          font-family: 'IBM Plex Sans Thai';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        "
      >
        เกี่ยวกับทุนการศึกษา
      </span>
    </v-col>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <v-card class="pa-6" width="100%">
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <span class="pt-2">ชื่อหัวข้อ (TH)</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <span class="pt-2">ชื่อหัวข้อ (EN)</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              hide-details
              v-model="form.title_th"
              placeholder="ชื่อหัวข้อ (TH)"
              dense
              outlined
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              hide-details
              v-model="form.title_en"
              placeholder="ชื่อหัวข้อ (EN)"
              dense
              outlined
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" sm="6"
            ><span class="pt-2">คำอธิบายแบบย่อ (TH)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="6" sm="6"
            ><span class="pt-2">คำอธิบายแบบย่อ (EN)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="6" sm="6">
            <v-textarea
              v-model="form.short_desc_th"
              placeholder="คำอธิบายแบบย่อ (TH)"
              dense
              outlined
              :rules="rules.required"
              counter="140"
              maxlength="140"
              disabled
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-textarea
              v-model="form.short_desc_en"
              placeholder="คำอธิบายแบบย่อ (EN)"
              dense
              outlined
              :rules="rules.required"
              counter="140"
              maxlength="140"
              disabled
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <span class="pt-2">มีแยกประเภทหรือไม่ ?</span>
            <v-radio-group v-model="form.seperate_type" row disabled>
              <v-radio label="ไม่มีแยกประเภท" value="ไม่มี"></v-radio>
              <v-radio label="มีแยกประเภท" value="มี"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>

      <v-col class="mt-10" v-if="form.seperate_type == 'ไม่มี'">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          รายละเอียด
        </span>
      </v-col>

      <v-card class="pa-6" width="100%" v-if="form.seperate_type == 'ไม่มี'">
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">เงื่อนไขและหลักเกณฑ์ในการรับทุน (TH)</span>
                <span style="color: red">*</span></v-col
              >
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">เงื่อนไขและหลักเกณฑ์ในการรับทุน (EN)</span>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <quill-editor
                  v-model="form.condition_th"
                  :content="content"
                  :options="editorOption"
                  @change="onEditorChange($event)"
                  disabled
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <quill-editor
                  v-model="form.condition_en"
                  :content="content1"
                  :options="editorOption"
                  @change="onEditorChange1($event)"
                  disabled
                />
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">จำนวนเงินทุน (TH)</span>
                <span style="color: red">*</span></v-col
              >
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">จำนวนเงินทุน (EN)</span>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <quill-editor
                  v-model="form.amount_of_capital_th"
                  :content="content2"
                  :options="editorOption"
                  @change="onEditorChange2($event)"
                  disabled
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <quill-editor
                  v-model="form.amount_of_capital_en"
                  :content="content3"
                  :options="editorOption"
                  @change="onEditorChange3($event)"
                  disabled
                />
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ระยะเวลาให้ทุน (TH)</span>
                <span style="color: red">*</span></v-col
              >
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ระยะเวลาให้ทุน (EN)</span>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <quill-editor
                  v-model="form.time_of_funding_th"
                  :content="content4"
                  :options="editorOption"
                  @change="onEditorChange4($event)"
                  disabled
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <quill-editor
                  v-model="form.time_of_funding_en"
                  :content="content5"
                  :options="editorOption"
                  @change="onEditorChange5($event)"
                  disabled
                />
              </v-col>

              <!-- <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ระยะเวลาให้ทุน</span>
                <span style="color: red">*</span></v-col
              >
              <v-col cols="12" md="6" sm="6">
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                hide-details
                      v-model="form.time_of_funding_start"
                       
                      dense
outlined
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.time_of_funding_start"
                    @input="startDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="1" sm="1">
                <span>ถึง</span>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                hide-details
                      v-model="form.time_of_funding_end"
                       
                      dense
outlined
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.required"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.time_of_funding_end"
                    @input="endDateMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col> -->
              <!-- <v-col cols="12" md="5" sm="5"></v-col> -->

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">วิธีขอทุน (TH)</span>
                <span style="color: red">*</span></v-col
              >
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">วิธีขอทุน (EN)</span>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <quill-editor
                  v-model="form.how_to_get_funding_th"
                  :content="content6"
                  :options="editorOption"
                  @change="onEditorChange6($event)"
                  disabled
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <quill-editor
                  v-model="form.how_to_get_funding_en"
                  :content="content7"
                  :options="editorOption"
                  @change="onEditorChange7($event)"
                  disabled
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>

      <v-row class="mt-10 ml-2" v-if="form.seperate_type == 'มี'">
        <v-col cols="12" md="9" sm="9">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
          >
            ประเภททุนการศึกษา
          </span>
        </v-col>
        <v-col
          cols="12"
          md="3"
          sm="3"
          v-if="isSubmit == false"
          class="text-right"
        >
          <!-- <span style="color: red">กด"ยืนยัน"เพื่อเพิ่มประเภททุนการศึกษา </span> -->
          <v-btn disabled @click="submit()" dark color="#2AB3A3">ยืนยัน</v-btn>
        </v-col>
        <!-- <v-col
          cols="12"
          md="3"
          sm="3"
          class="text-right"
          v-if="isSubmit == true"
        >
          <DialogSeperateType
            :scholarshipId="scholarshipId"
            @getAllType="getAllType"
          />
          <DialogSeperateTypeEdit
            :itemEditDialog="itemEditDialog"
            :isDialog="isDialog"
            :scholarshipId="scholarshipId"
            @getAllType="getAllType"
            @closeDialogSeperateTypeEdit="closeDialogSeperateTypeEdit"
          />
        </v-col> -->
      </v-row>

      <v-row v-if="form.seperate_type == 'มี'">
        <v-col cols="12" md="12" sm="12">
          <v-row>
            <!-- <v-col cols="12" md="1" sm="1">
              <span class="text-h6">ประเภท</span>
            </v-col>
            <v-col cols="12" md="11" sm="11">
              <DialogSeperateType
                :scholarshipId="scholarshipId"
                @getAllType="getAllType"
              />
              <DialogSeperateTypeEdit
                :itemEditDialog="itemEditDialog"
                :isDialog="isDialog"
                :scholarshipId="scholarshipId"
                @getAllType="getAllType"
                @closeDialogSeperateTypeEdit="closeDialogSeperateTypeEdit"
              />
            </v-col> -->
            <v-col cols="12" md="12" sm="12">
              <v-card
                ><v-data-table
                  :headers="headersType"
                  :items="typeItems"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <!-- <template v-slot:[`item.action`]="{ item }">
                    <v-icon @click="ViewSeperate(item.id)">mdi-pencil</v-icon>
                    <v-icon @click="DeleteSeperate(item)">mdi-delete</v-icon>
                  </template> -->
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-col class="mt-10" v-if="form.seperate_type == 'ไม่มี'">
        <span
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          อัพโหลดเอกสารทุน
        </span>
      </v-col>

      <v-card class="pa-6" width="100%" v-if="form.seperate_type == 'ไม่มี'">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2"><b>เอกสารทุน</b></span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="12" md="9" sm="9">
            <v-col
              cols="12"
              md="12"
              sm="12"
              class="text-left"
              v-for="(item, indexItemDocs) in this.documentScholarships"
              :key="indexItemDocs"
            >
              <v-row>
                <v-col cols="12" md="3" sm="3">
                  <p
                    style="color: #4caf50; cursor: pointer"
                    @click="onClickFile(item.path)"
                  >
                    {{ item.name }}
                  </p>
                </v-col>
                <v-col cols="12" md="9" sm="9" class="mt-n3">
                  <v-btn
                    color="red"
                    icon
                    @click="deleteFilesDocs(indexItemDocs)"
                    disabled
                  >
                    <v-icon>mdi-alpha-x-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <input
                type="file"
                accept="application/pdf,"
                id="file"
                ref="form.attachFile1"
                v-on:change="submitFile('form.attachFile1')"
                disabled
              />
              <!-- <v-btn small @click="submitFile('form.attachFile1')"
                >upload</v-btn
              > -->
            </v-col>
          </v-col>
        </v-row>
      </v-card>

      <!-- <v-row
        align="center"
        justify="end"
        v-if="form.seperate_type == 'ไม่มี'"
        class="pa-5"
      >
        <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
        <v-btn @click="submit2()" dark color="#2AB3A3">บันทึก</v-btn>
      </v-row>
      <v-row
        align="center"
        justify="end"
        v-if="form.seperate_type == 'มี'"
        class="pa-5"
      >
        <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
        <v-btn @click="submit()" dark color="#2AB3A3">บันทึก</v-btn>
        <v-btn v-if="isSubmit == false" @click="submit()" dark color="#2AB3A3"
          >บันทึก</v-btn
        >
      </v-row> -->
    </v-form>
  </v-row>
</template>
<script>
import moment from "moment";
import { Decode, Encode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DialogSeperateType from "../Dialogs/DialogSeperateType.vue";
import DialogSeperateTypeEdit from "../Dialogs/DialogSeperateTypeEdit.vue";

export default {
  components: {
    Loading,
    VueEditor,
    DialogSeperateType,
    DialogSeperateTypeEdit,
  },
  data() {
    return {
      isDialog: false,
      scholarshipId: 0,
      isSubmit: false,
      getToday: new Date().toLocaleString(),
      today: "",
      status: "เปิดใช้งาน",
      statusItems: [
        { id: 1, name: "เปิดใช้งาน" },
        {
          id: 2,
          name: "ปิดใช้งาน",
        },
        {
          id: 3,
          name: "ฉบับร่าง",
        },
      ],
      form: {
        typeCourse: "ภาคปกติ",
        title_th: null,
        title_en: null,
        short_desc_th: null,
        short_desc_en: null,
        seperate_type: "ไม่มี",
        condition_th: null,
        condition_en: null,
        amount_of_capital_th: null,
        amount_of_capital_en: null,
        time_of_funding_th: null,
        time_of_funding_en: null,
        how_to_get_funding_th: null,
        how_to_get_funding_en: null,
        levelId: 13,

        attachFile1: "",
      },
      documentScholarships: [],

      typeItems: [],

      startDateMenu: false,
      endDateMenu: false,

      content: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      content8: "",
      content9: "",

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "CONTENT",
      category: "",
      desc: "",
      brand: "",
      itemsBrand: [],
      img: "",
      loading: false,
      content_url: "",
      loading: false,
      file: [],
      video_url: "",
      itemTypes: [
        {
          name: "CONTENT",
          val: "CONTENT",
        },
        {
          name: "VIDEO",
          val: "VIDEO",
        },
      ],
      itemCategory: [
        {
          name: "แนะนำ",
          val: "SUGGEST",
        },
        {
          name: "ครอบครัว",
          val: "HOME2",
        },
        {
          name: "งานวิจัย",
          val: "RESEARCH",
        },
      ],

      headersType: [
        { text: "ลำดับ", value: "sortindex", align: "start" },
        {
          text: "ชื่อประเภท",
          value: "name_th",
          align: "start",
        },
        {
          text: "จำนวนเงินทุน(TH)",
          value: "amount_of_capital_th",
          align: "start",
        },
        {
          text: "ดาวน์โหลดเอกสารทุน",
          value: "",
          align: "start",
        },
        { text: "ปุ่มจัดการ", value: "action", align: "center" },
      ],
      levelItems: [],
      FacultyItems: [],

      itemsType: [],
      itemSchorlarships: [],

      dataScholarships: [],
      itemEditDialog: {},
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.dataScholarships = JSON.parse(
      Decode.decode(localStorage.getItem("dataScholarships"))
    );
    this.scholarshipId = this.dataScholarships.id;
    this.getOne();
    this.getAllType();
    this.setDate();
  },
  methods: {
    deleteFilesDocs(val) {
      this.documentScholarships.splice(val, 1);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    onEditorChange2({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content2 = html;
    },
    onEditorChange3({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content3 = html;
    },
    onEditorChange4({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content4 = html;
    },
    onEditorChange5({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content5 = html;
    },
    onEditorChange6({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content6 = html;
    },
    onEditorChange7({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content7 = html;
    },
    onEditorChange8({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content8 = html;
    },
    onEditorChange9({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content9 = html;
    },

    async ViewSeperate(itemId) {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/scholarshipTypes/${itemId}`,
        auth
      );
      console.log("ViewSeperate", response.data.data);
      this.itemEditDialog = response.data.data;

      this.isDialog = true;
    },
    closeDialogSeperateTypeEdit() {
      this.isDialog = false;
    },
    async getOne() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/scholarships/${this.dataScholarships.id}`,
        auth
      );
      console.log("getOne", response.data.data);
      this.form = response.data.data;
      this.status = response.data.data.status;
      this.loading = false;
    },
    convertDate(val) {
      return moment(val).format("DD/MM/YYYY");
    },

    // async getSchorlarshipsId() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/scholarships`
    //   );
    //   this.itemSchorlarships = response.data.data;
    //   console.log("getSchorlarshipsId", this.itemSchorlarships);
    //   this.scholarshipId = this.itemSchorlarships[0].id;
    // },

    setDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      this.today = date;
    },
    async getAllType() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/scholarshipTypes?scholarshipId=${this.scholarshipId}`,
        auth
      );
      console.log("getAll", response.data.data);
      this.typeItems = response.data.data;
      for (let i in this.typeItems) {
        this.typeItems[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },

    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        this.loading = true;
        if (this.$refs[questionNo].files[0]) {
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `https://gscm-service.yuzudigital.com/files/formData`,
            formData,
            auth
          );
          if (questionNo == "form.attachFile1") {
            // this.form.attachFile1 = response.data.data.path;
            this.documentScholarships.push({
              name: this.$refs[questionNo].files[0].name,
              path: response.data.data.path,
            });
          }
          console.log("SUCCESS!!");
          console.log("response", response);
        }
        this.loading = false;
      }
    },

    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageScholarshipsPhD");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },

    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.image_url_en = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },
    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        //   await this.submitfileMain(pic);
        //   console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          ...this.form,
          documentScholarships: this.documentScholarships,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/scholarships/${this.dataScholarships.id}`,
          // this.form,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขทุนการศึกษาสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageScholarshipsPhD");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    async submit1(pic) {
      this.$swal.fire({
        icon: "success",
        text: `ยืนยันสำเร็จ`,
        showConfirmButton: false,
        timer: 1500,
      });
      this.isSubmit = true;
      this.$router.push("ManageScholarshipsPhD");
    },
    async DeleteSeperate(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/scholarshipTypes/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllType();
        }
      });
    },
  },
};
</script>
